.mainContainer {
    padding: 10px 24px;
    margin: 0.4% 1%;
    background-color: #1f283e !important;
    color: white !important;
    border-radius: 10px;
}

.subHeader {
    padding: 1% 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heading {
    text-align: center !important;
}

.container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* justify-content: space-between; */
    margin-left: 5%;
    width: 100%;
}