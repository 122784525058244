.navSportButton {
    /* border: 1px solid rgba(48, 48, 48, .28) !important;
    border-radius: 22px; */
    line-height: 1.2;
    cursor: pointer;
    color: black !important;
    padding: 2px 7px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    white-space: nowrap;
    margin-left: 15px;
}

.leaguesNav {
    margin: 2% -0%;
    .slick-next:before,
    .slick-prev:before {
        color: black !important
    }
}

.boldText {
    font-weight: bold !important;
}


/* .nav-leagues-container */

.navLeaguesContainer {
    background-color: var(--primary-background-color) !important;
    color: white !important;
    position: relative;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 1%;
    max-width: 100% !important;
    /* Add this to limit the width of the container */
    overflow-x: auto !important;
    /* Instead of hidden, allow a scrollbar if needed */
    display: block;
}

.flex-item {
    color: var(--primary-text-color) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    font-weight: 700;
    /* // border-radius: 25px; */
    padding: 5px;
}

.flex-item:hover {
    cursor: pointer;
}

@media (max-width:767px) {
    .navLeaguesContainer {
        /* // display: none !important; */
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .navSportButton {
        font-size: 0.8rem;
    }
}