/* // .right-container {
//     border: 1px solid #E0E0E0;
//     border-radius: 7px;
//     margin-top: 8px;
//     max-height: 80vh;
//     overflow: scroll;
// }
// .right-container {
//     background-color: black !important;
//     color: white !important;
// } */

.right-container {
    margin-top: 15px;
    background-color: #1a2035;
}

@media (max-width: 767px) {
    .right-container {
        display: none !important;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .right-container {
        display: none !important;
    }
}