.firstChild {
    margin-right: 20px;
    flex: 0 0 20%;
}

.headingStyle {
    background-color: #1a2035;
    color: white !important;
    text-align: center;
}

.paddingLeft {
    padding-left: 6% !important;
    cursor: pointer;
}