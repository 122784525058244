.DetailContainer {
    padding: 10px 20px;
    margin: 1.3% 1%;
    background-color: #1f283e;
    color: white;
    border-radius: 10px;
}

.odd-container {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}

.subHeader {
    display: flex;
    justify-content: space-between;
}