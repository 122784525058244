.appContainer {
    display: flex;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #1a2035;
}

.mainContent {
    flex-grow: 1;
    /* This will make the main content take up the remaining space */
    padding: 20px;
    overflow-y: auto;
    /* Add scrolling if the content overflows */
    margin-left: 10px;
}