.bet-summary-container {
    display: flex;
    .bet-description {
        display: flex;
        justify-content: space-between;
    }
    .bet-team {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 0% !important;
        /* padding: 10px 0px; */
    }
    .bet-amount {
        padding-top: 0% !important;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.addToBetslipButton {
    all: unset;
    background-color: #8ec045;
    color: #ffffff;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    font-weight: 900;
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
    width: 100%;
}

.addToBetslipButton:disabled {
    background-color: #d3d3d3;
    color: #8e8e8e;
    cursor: not-allowed;
}

.inputAdornment {
    color: black !important;
    p {
        color: black !important;
    }
}

.stake-per-bet-input {
    background-color: white !important;
    color: black !important;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
}

.terminal-code-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    font-weight: bold;
}

.bet-summary-main-container {
    background-color: var(--tertary-background-color) !important;
    color: white !important;
}

@media screen and (max-width: 1024px) {
    .bet-summary-main-container {
        position: fixed;
        bottom: 0%;
        width: 100%;
    }
}

.stake-per-bet-input {
    background-color: var(--secondary-background-color);
}

.placeBetBtn {
    background-color: var(--tertary-background-color) !important;
}