.header-top-container {
    display: flex;
    justify-content: space-between;
    margin: 1% 1.9%;
    font-family: 'Poppins', sans-serif;
    .app-name {
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap !important;
        margin-right: 5%;
    }
}

.mainContainer {
    padding: 10px 24px;
    padding-bottom: 20px;
    margin: 0.4% 1%;
    background-color: #1f283e !important;
    color: white !important;
    border-radius: 10px;
}

.flex-container {
    display: flex;
    justify-content: space-between;
}

.ConfirmRegistration_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30%;
}

.ConfirmRegistration_avatar {
    margin: 8px;
    background-color: var(--secondary-background-color);
}

.ConfirmRegistration_form {
    width: 100%;
    margin-top: 24px;
}

.ConfirmRegistration_submitButton {
    margin-top: 24px !important;
    margin-bottom: 16px !important;
}