.bet-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bet-main-container {
    background-color: var(--secondary-background-color) !important;
    /* background-color: var(--primary-background-color); */
    color: white !important;
    /* border: 1px solid rgba(255, 255, 255, .28); */
    border-bottom: 1px solid rgba(255, 255, 255, .28);
}

.bet-actions {
    display: flex;
    justify-content: flex-end;
}

.bet-description {}

.bet-team {
    margin: 0 !important;
    padding: 0 !important;
    /* margin: 10px 0; */
    padding-bottom: 1% !important;
}

.bet-odd {
    display: flex;
    align-items: center;
    font-weight: 900;
    color: var(--odd-background-color);
}

.remove-bet {
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.bet-amount {
    /* padding-top: 8%;
     */
    display: flex;
    align-items: center;
    justify-content: center;
}

.match-result {
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    font-weight: 900;
    color: var(--odd-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.match-detail {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.potential-payment {
    font-size: 11px;
    font-family: 'Poppins', sans-serif;
    color: #A0A0A0;
}

.payout-amount {
    color: black;
}

.bet-checkbox {
    display: flex;
    align-items: center;
}

.css-heg063-MuiTabs-flexContainer {
    justify-content: space-around !important;
}