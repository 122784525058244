.sidebar {
    width: 250px;
    height: 94vh;
    margin: 1% 0.6%;
    border-radius: 10px;
    background-color: #1f283e;
    overflow-x: hidden;
    padding-top: 20px;
    flex-shrink: 0;
}

.sidebar a {
    /* Padding for links */
    text-decoration: none;
    /* No underline */
    font-size: 18px;
    /* Font size */
    color: white;
    /* Text color */
    display: block;
    /* Display as block */
}

.sidebar a:hover {
    color: #f1f1f1;
    /* Color on hover */
}

.activeLink {
    width: 100%;
    background-color: #1a2035;
    /* Background color for active link */
}