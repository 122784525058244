.popup-signup {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    color: black;
}

.mainContainer {
    padding: 10px 24px;
    padding-bottom: 20px;
    margin: 0.4% 1%;
    background-color: #1f283e !important;
    color: white !important;
    border-radius: 10px;
}

.stepper {
    margin: 2% 30%;
}

.popup-signup-inner {
    background-color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* // padding: 20px; */
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    width: 30%;
}

.login-form {
    margin-bottom: 5%;
}

.signup-header {
    background-color: var(--primary-background-color);
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 2% 3%;
}

.signup-stepper {
    padding: 5%;
}

.close-signup {
    cursor: pointer;
}

.login-submit-btn {
    font-size: 15px !important;
    background: transparent;
    /* color: black; */
    color: white;
    background-color: black;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    border: none;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, .28);
    border-radius: 22px;
    padding: 6px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
}

.login-btn-submit-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2% 0%;
}

.ConfirmRegistration_submitButton {
    margin-top: 24px !important;
    margin-bottom: 16px !important;
}

@media (max-width: 600px) {
    .popup-signup-inner {
        width: 80%;
    }
}