.right-bet-container {
    .css-heg063-MuiTabs-flexContainer {
        display: grid !important;
        grid-template-columns: 1fr 1fr !important;
    }
}

.wallet-container {
    .css-heg063-MuiTabs-flexContainer {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr !important;
    }
}

.bet-cards-container {
    max-height: 400px;
    overflow-y: scroll;
}

.bet-slip-tabs {
    background-color: var(--primary-background-color);
}

.stake-input {
    background-color: var(--secondary-background-color) !important;
}

@media screen and (max-width: 1024px) {
    .bet-cards-container {
        max-height: 70vh;
        overflow-y: scroll;
    }
}

@media screen and (max-width: 767px) {
    .bet-cards-container {
        max-height: 60vh;
    }
}