.css-19kzrtu {
    padding: 0 !important;
}

.betCard {
    color: black;
}

.right-bet-container {
    .css-12dv9jb {
        min-width: 0px !important;
    }
}