.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin: 1.3% 1%;
    background-color: #1f283e;
    color: white;
    border-radius: 10px;
}

.title {
    font-size: 24px;
    cursor: pointer;
}

.button {
    margin-left: 10px;
    padding: 5px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button:hover {
    background-color: #45a049;
}