.mainContainer {
    padding: 10px 24px;
    margin: 0.4% 1%;
    background-color: #1f283e !important;
    color: white !important;
    border-radius: 10px;
}

.tablesContainer {
    display: flex;
}

.parent {
    display: flex;
    /* Enables Flexbox */
}

.firstChild {
    margin-right: 20px;
    flex: 0 0 20%;
    /* Takes up 30% of the parent div */
    /* More styles if needed */
}

.secondChild {
    flex: 0 0 80%;
    /* Takes up 70% of the parent div */
    /* More styles if needed */
}

.paddingLeft {
    padding-left: '6%';
}

.headingStyle {
    background-color: #1a2035;
    color: white !important;
    text-align: center;
}

.subHeader {
    display: flex;
    justify-content: space-between;
}