.secondChild {
    flex: 0 0 78%;
}

.headingStyle {
    background-color: #1a2035;
    color: white !important;
    text-align: center;
}

.paddingLeft {
    padding-left: 3% !important;
    cursor: pointer;
    text-align: center;
}

.textCenter {
    text-align: center;
}

.odd-container {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
}