.popup {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    color: black;
}

.app-name {
    color: var(--primary-text-color) !important;
    font-weight: 900;
    font-size: 15px;
}

.LoginButton {
    all: unset;
    background-color: #8ec045;
    color: #ffffff;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    font-weight: 900;
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
    width: 100%;
}

.LoginButton:disabled {
    background-color: #d3d3d3;
    color: #8e8e8e;
    cursor: not-allowed;
}

.popup-inner {
    background-color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    padding-top: 1%;
    transform: translate(-50%, -50%);
    /* // padding: 20px; */
    background-color: #1a2035;
    /* border-radius: 5px; */
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    width: 30%;
}

.login-form {
    margin-bottom: 5%;
    .form-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.inputLabel {
    color: var(--primary-text-color) !important;
    margin-top: -2.8% !important;
}

.login-header {
    background-color: var(--primary-background-color);
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 2% 3%;
}

.password-input {
    margin: 5% 0% !important;
    background-color: var(--white-background-color);
}

.email-input {
    margin-top: 5% !important;
    margin-bottom: 1% !important;
    background-color: var(--white-background-color);
}

.password-label {
    display: flex;
    align-items: center;
    justify-content: center;
}

.remember-email {
    display: flex;
    justify-content: space-between;
    padding: 1% 21%;
}

.login-close {
    color: var(--light-green-background-color);
    font-size: 2rem;
    font-weight: bold;
}

.login-submit-btn {
    background-color: var(--light-green-background-color);
    padding: 1% 2.4%;
    font: inherit;
    cursor: pointer;
    border: none;
    text-wrap: nowrap;
    color: var(--primary-text-color);
}

.divider-row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3% 23%;
    span {
        padding: 0% 3%;
    }
    hr {
        flex: 1;
    }
}

.close-login {
    cursor: pointer;
}

.link-no-underline {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: var(--light-green-background-color);
    font-size: 0.8rem;
    margin: 0.6% 0%;
}

.forgotPasswordContainer {
    display: flex;
    width: 80%;
}

@media only screen and (max-width: 1024px) {
    .popup-inner {
        width: 44%;
    }
}

@media (max-width: 767px) {
    .popup-inner {
        width: 96%;
    }
    .link-no-underline {
        font-size: 0.8rem;
    }
    .password-input {
        margin: 5% 0% !important;
        background-color: var(--white-background-color);
    }
    .email-input {
        margin-top: 5% !important;
        margin-bottom: 1% !important;
        background-color: var(--white-background-color);
    }
    .login-submit-btn {
        margin-top: 4%;
    }
}